import { DateRange, Today } from '@mui/icons-material'
import { Box, IconButton, Popover, Slider, Stack } from '@mui/material'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { ptBR } from 'date-fns/locale'
import { useRef, useState } from 'react'
import { localToUtc, utcToLocal } from '../../../utils/utcDateTime'
import { format } from 'date-fns'
import { AllDatesTracker, TrackerCheckbox } from '../details'
import { SweepOverviewPoint } from '../../../types/SweepOverview'

interface SliderRangePointsI {
  isImpersonate: boolean
  someChecked: boolean
  trackers: TrackerCheckbox[] | []
  allDatesTracker: AllDatesTracker[]
  filterRangeDate: number[] | null
  setFilterChanged: React.Dispatch<React.SetStateAction<boolean>>
  setFilterRangeDate: React.Dispatch<React.SetStateAction<number[] | null>>
  setSavedFilterRangeDate: React.Dispatch<React.SetStateAction<string[] | null>>
  timeStart: Date | string | null
  setTimeStart: React.Dispatch<React.SetStateAction<Date | null>>
  timeEnd: Date | string | null
  setTimeEnd: React.Dispatch<React.SetStateAction<Date | null>>
  executionDate?: string | null
  showAllRadius: (tracker: string, remove?: boolean) => void
  plotPointTracker: (rangeTimeSet?: number[]) => void
  getRangeTimeToFilter: () => {
    rangeTimeSorted: AllDatesTracker[]
  }
  getHistoryPointsByTrackerCode: (
    trackerCode: number,
    checked?: boolean,
    allChecked?: boolean
  ) => Promise<SweepOverviewPoint[] | undefined>
  specialService?: boolean
  trackSweepOverview?: boolean
  isPoolingActive?: boolean
}

function SliderRangePoints(props: SliderRangePointsI) {
  const [anchorElStart, setAnchorElStart] = useState<HTMLButtonElement | null>(
    null
  )
  const [openStart, setOpenStart] = useState(false)
  const [fetchedStart, setFetchedStart] = useState(false)
  const [fetchedEnd, setFetchedEnd] = useState(false)

  const [anchorElEnd, setAnchorElEnd] = useState<HTMLButtonElement | null>(null)
  const [openEnd, setOpenEnd] = useState(false)

  const getNameForDateRange = (positionRange: number) => {
    if (props.allDatesTracker.length && props.filterRangeDate) {
      const date = props.allDatesTracker.find(
        (item) => item.value === props.filterRangeDate?.[positionRange]
      )?.date
      if (date) {
        return utcToLocal(date!, 'HH:mm:ss')
      }
      return ''
    }
    return ''
  }

  const saveFilterRangeDate = (currentFilterRangeDate: number[]) => {
    try {
      let times: string[] = []
      const result = props.getRangeTimeToFilter()

      currentFilterRangeDate?.forEach((_, index) => {
        const time = result.rangeTimeSorted.find(
          (item) => item.value === currentFilterRangeDate[index]
        )?.date
        if (time) {
          times = [...times, String(utcToLocal(time!, 'HH:mm:ss'))]
        }
      })
      props.setSavedFilterRangeDate(times)
      props.setFilterRangeDate(currentFilterRangeDate)
    } catch (err) {
      console.log(err)
    }
  }

  function findSmallestDate(array: any[]) {
    if (array.length === 0) {
      return null
    }
    const haveRoadSnap = array.some((item) => item.roadSnap)

    let newArray = !props.specialService
      ? haveRoadSnap
        ? array.filter((item) => item.roadSnap)
        : array
      : array
    if (fetchedStart) {
      newArray = array.slice(1, array.length)
    }

    const dateSearch = newArray.reduce(
      (lessDate: number, object: { date: number }) => {
        return object.date < lessDate ? object.date : lessDate
      },
      newArray[0].date
    )

    return utcToLocal(dateSearch, 'yyyy-MM-dd HH:mm')
  }

  function findLargestDate(array: any[]) {
    if (array.length === 0) {
      return null
    }

    const haveRoadSnap = array.some((item) => item.roadSnap)

    let newArray = !props.specialService
      ? haveRoadSnap
        ? array.filter((item) => item.roadSnap)
        : array
      : array
    if (fetchedEnd) {
      newArray = array.slice(0, array.length - 1)
    }

    const dateSearch = newArray.reduce(
      (highDate: number, object: { date: number; roadSnap: boolean }) => {
        if (!object.roadSnap && !props.specialService) {
          return highDate
        }
        return object.date > highDate ? object.date : highDate
      },
      newArray[0].date
    )

    return utcToLocal(dateSearch, 'yyyy-MM-dd HH:mm')
  }

  function getClosestTimeDown(value: any, allTimes: any[]) {
    if (!value || !allTimes || allTimes.length === 0) {
      return null
    }
    const targetTime = new Date(
      `${format(
        new Date(props.executionDate!.replace('-', '/')),
        'yyyy/MM/dd'
      )} ${localToUtc(value!, 'HH:mm:ss')}`
    )

    const closestTimes = allTimes
      .map((time) => time.date)
      .filter((time) => time <= targetTime)
      .sort((a, b) => a - b)

    if (closestTimes.length === 0) {
      return null
    }

    const closestTime = new Date(
      utcToLocal(closestTimes[closestTimes.length - 1], 'yyyy/MM/dd HH:mm:ss')
    )

    const formattedTime = `${String(closestTime.getHours()).padStart(
      2,
      '0'
    )}:${String(closestTime.getMinutes()).padStart(2, '0')}:${String(
      closestTime.getSeconds()
    ).padStart(2, '0')}`

    return formattedTime
  }

  function getClosestTimeUp(value: Date, allTimes: any[]) {
    if (!value || !allTimes || allTimes.length === 0) {
      return null
    }
    const targetTime = new Date(
      `${format(
        new Date(props.executionDate!.replace('-', '/')),
        'yyyy/MM/dd'
      )} ${localToUtc(value!, 'HH:mm:ss')}`
    )

    const closestTimes = allTimes
      .map((time) => time.date)
      .filter((time) => time >= targetTime)
      .sort((a, b) => a - b)

    if (closestTimes.length === 0) {
      return null
    }

    const closestTime = new Date(
      utcToLocal(closestTimes[0], 'yyyy/MM/dd HH:mm:ss')
    )

    const formattedTime = `${String(closestTime.getHours()).padStart(
      2,
      '0'
    )}:${String(closestTime.getMinutes()).padStart(2, '0')}:${String(
      closestTime.getSeconds()
    ).padStart(2, '0')}`

    return formattedTime
  }

  function getAproximateDate(value: any, type: 'start' | 'end') {
    let aproxDate: string | null = null
    if (type === 'start') {
      aproxDate = getClosestTimeDown(value, props.allDatesTracker)
    } else {
      aproxDate = getClosestTimeUp(value, props.allDatesTracker)
    }

    const result = props.getRangeTimeToFilter()
    const indexNumber = result.rangeTimeSorted?.findIndex(
      (item) => utcToLocal(item.date!, 'HH:mm:ss') === aproxDate
    ) as number

    if (props.filterRangeDate) {
      let filterRangeDateEnd = props.filterRangeDate[1]
      if (filterRangeDateEnd > result.rangeTimeSorted.length - 1) {
        filterRangeDateEnd = result.rangeTimeSorted.length - 1
      }
      if (type === 'start') {
        saveFilterRangeDate([
          indexNumber,
          filterRangeDateEnd ?? result.rangeTimeSorted.length - 1
        ])
        props.plotPointTracker([
          indexNumber,
          filterRangeDateEnd ?? result.rangeTimeSorted.length - 1
        ])
      } else if (type === 'end') {
        saveFilterRangeDate([
          props.filterRangeDate ? props.filterRangeDate[0] : 0,
          indexNumber
        ])
        props.plotPointTracker([
          props.filterRangeDate ? props.filterRangeDate[0] : 0,
          indexNumber
        ])
      }
    }
  }

  const sliderRef = useRef(null)
  const buttonRef = useRef(null)

  if (props.allDatesTracker.length === 0 || !props.someChecked) {
    return <></>
  }

  return (
    <Box
      px={2}
      mt={2}
      height={props.specialService ? '70px' : '70px'}
      width={props.specialService ? '280px' : '100%'}
    >
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1 }}
        alignItems="center"
        ref={sliderRef}
      >
        {props.isImpersonate || props.trackSweepOverview ? (
          <IconButton
            disabled={props.isPoolingActive}
            onClick={(event) => {
              setAnchorElStart(event.currentTarget)
              setOpenStart(true)
            }}
          >
            <DateRange />
          </IconButton>
        ) : (
          <DateRange />
        )}
        <Popover
          open={openStart}
          anchorEl={anchorElStart}
          onClose={() => {
            props.setFilterChanged(false)
            const searchLargestDate = findLargestDate(props.allDatesTracker)
            const searchSmallestDate = findSmallestDate(props.allDatesTracker)

            if (
              props.timeStart instanceof Date &&
              searchSmallestDate &&
              searchLargestDate
            ) {
              const formattedtimeStart = props.timeStart
              const formattedSmallestDate = new Date(searchSmallestDate)
              const formattedLargestDate = new Date(searchLargestDate)

              if (
                formattedtimeStart >= formattedSmallestDate &&
                formattedtimeStart <= formattedLargestDate
              ) {
                getAproximateDate(props.timeStart, 'start')
                setFetchedEnd(false)
              } else {
                const trackerCheck = props.trackers.filter(
                  (item) => item.checked
                )

                const asyncOperations = trackerCheck.map(async (item) => {
                  await props.getHistoryPointsByTrackerCode(
                    item.trackerCode!,
                    true
                  )
                })

                Promise.all(asyncOperations)
                  .then(() => {
                    setFetchedStart(true)
                  })
                  .catch((error) => {
                    console.error('Erro ao buscar dados do histórico:', error)
                  })
              }
            } else {
              if (props.filterRangeDate && props.timeStart) {
                getAproximateDate(props.timeStart, 'start')
                setFetchedStart(false)
              }
            }

            setOpenStart(false)
            setAnchorElStart(null)
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
        >
          <Box
            sx={{ p: 2, width: 200 }}
            alignItems="center"
            display="flex"
            justifyContent={'row'}
            gap={1}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <TimePicker
                label="Hora inicial"
                value={new Date(props.timeStart ?? '') ?? null}
                onChange={(value) => {
                  if (
                    value &&
                    value instanceof Date &&
                    !isNaN(value.getTime())
                  ) {
                    const formatDate = `${format(
                      new Date(props.executionDate!.replace('-', '/')),
                      'yyyy/MM/dd'
                    )} ${format(value, 'HH:mm:ss')}`
                    props.setTimeStart(new Date(formatDate))
                  }
                }}
                closeOnSelect={false}
                ampm={true}
                format="HH:mm"
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true }
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </Popover>
        <Slider
          aria-label="Volume"
          getAriaLabel={() => 'Volume'}
          value={props.filterRangeDate ?? []}
          onChangeCommitted={(e, value) => {
            props.setFilterChanged(true)

            saveFilterRangeDate(value as number[])
            if (props.specialService) {
              props.plotPointTracker(value as number[])
            } else {
              props.plotPointTracker()
            }
            const checkedTrackers = props.trackers.filter(
              (tracker) => tracker.checked
            )
            checkedTrackers.map((tracker) => {
              props.showAllRadius(tracker.label, true)
            })
          }}
          onChange={(e, value: number[] | number) => {
            props.setFilterChanged(true)
            saveFilterRangeDate(value as number[])
          }}
          marks={[
            {
              value: 0,
              label: utcToLocal(
                props.allDatesTracker[0]?.date ?? new Date(),
                'HH:mm'
              )
            },
            {
              value: props.allDatesTracker.length - 1,
              label: utcToLocal(
                props.allDatesTracker[props.allDatesTracker.length - 1]?.date ??
                  new Date(),
                'HH:mm'
              )
            }
          ]}
          max={props.allDatesTracker.length - 1}
          valueLabelFormat={(_, index: number) => {
            if (props.allDatesTracker) {
              return getNameForDateRange(index)
            }
          }}
          valueLabelDisplay="auto"
          ref={buttonRef}
        />
        {props.isImpersonate || props.trackSweepOverview ? (
          <IconButton
            onClick={(event) => {
              setAnchorElEnd(event.currentTarget)
              setOpenEnd(true)
            }}
          >
            <Today />
          </IconButton>
        ) : (
          <Today />
        )}
        <Popover
          open={openEnd}
          anchorEl={anchorElEnd}
          onClose={() => {
            props.setFilterChanged(false)
            const searchLargestDate = findLargestDate(props.allDatesTracker)
            const searchSmallestDate = findSmallestDate(props.allDatesTracker)

            if (
              props.timeEnd instanceof Date &&
              searchSmallestDate &&
              searchLargestDate
            ) {
              const formattedtimeStart = props.timeEnd
              const formattedSmallestDate = new Date(searchSmallestDate)
              const formattedLargestDate = new Date(searchLargestDate)

              if (
                formattedtimeStart >= formattedSmallestDate &&
                formattedtimeStart <= formattedLargestDate
              ) {
                getAproximateDate(props.timeEnd, 'end')
                setFetchedStart(false)
              } else {
                const trackerCheck = props.trackers.filter(
                  (item) => item.checked
                )

                const asyncOperations = trackerCheck.map(async (item) => {
                  await props.getHistoryPointsByTrackerCode(
                    item.trackerCode!,
                    true
                  )
                })

                Promise.all(asyncOperations)
                  .then(() => {
                    setFetchedStart(true)
                  })
                  .catch((error) => {
                    console.error('Erro ao buscar dados do histórico:', error)
                  })
              }
            } else {
              if (props.filterRangeDate && props.timeEnd) {
                getAproximateDate(props.timeEnd, 'end')
                setFetchedStart(false)
              }
            }

            setOpenEnd(false)
            setAnchorElEnd(null)
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <Box
            sx={{ p: 2, width: 250 }}
            alignItems="center"
            display="flex"
            justifyContent={'row'}
            gap={1}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <TimePicker
                label="Hora Final"
                closeOnSelect={false}
                ampm={true}
                format="HH:mm"
                value={props.timeEnd ? new Date(props.timeEnd ?? '') : null}
                onChange={(value) => {
                  if (
                    value &&
                    value instanceof Date &&
                    !isNaN(value.getTime())
                  ) {
                    const formatDate = `${format(
                      new Date(props.executionDate!.replace('-', '/')),
                      'yyyy/MM/dd'
                    )} ${format(value, 'HH:mm:ss')}`
                    props.setTimeEnd(new Date(formatDate))
                  }
                }}
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true }
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
        </Popover>
      </Stack>
    </Box>
  )
}

export default SliderRangePoints
